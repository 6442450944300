import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import Loading from './components/common/graphs/Loading';
import { Box } from '@mui/material';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const printBuildInfo = () => {
  // @ts-ignore
  const feCommitTimestamp = window.envVars.REACT_APP_BUILD_TIMESTAMP;
  // @ts-ignore
  const beCommitTimestamp = window.envVars.BE_BUILD_TIMESTAMP;

  console.debug('Build info', {
    // @ts-ignore
    feCommit: window.envVars.REACT_APP_BUILD_COMMIT,
    feCommitTime: feCommitTimestamp ? new Date(Number(feCommitTimestamp) * 1000).toLocaleString() : undefined,
    // @ts-ignore
    beCommit: window.envVars.BE_BUILD_COMMIT,
    beCommitTime: beCommitTimestamp ? new Date(Number(beCommitTimestamp) * 1000).toLocaleString() : undefined
  });
};

const App = lazy(() => import('./App'));

(async () => {
  // @ts-ignore
  window.envVars = {
    NODE_ENV: process.env.NODE_ENV!,
    PORT: process.env.PORT!,
    BE_BUILD_COMMIT: process.env.BE_BUILD_COMMIT!,
    BE_BUILD_TIMESTAMP: process.env.BE_BUILD_TIMESTAMP!,
    REACT_APP_API_ROOT: process.env.REACT_APP_API_ROOT!,
    REACT_APP_OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER!,
    REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID!,
    REACT_APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL!,
    REACT_APP_BUILD_COMMIT: process.env.REACT_APP_BUILD_COMMIT!,
    REACT_APP_BUILD_TIMESTAMP: process.env.REACT_APP_BUILD_TIMESTAMP!,
    REACT_APP_LAUNCH_DARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID!
  };

  // @ts-ignore
  if (window.envVars.NODE_ENV === 'production') {
    // @ts-ignore
    window.envVars = (await axios.get('/env-configuration')).data;
  }

  printBuildInfo();

  const LDProvider = await asyncWithLDProvider({
    // @ts-ignore
    clientSideID: window.envVars.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
  });

  const fallback = (
    <Box sx={{ height: '100vh', width: '100vw' }} display="flex" justifyContent="center" alignItems="center">
      <Loading />
    </Box>
  );

  const root = createRoot(document.getElementById('root')!);
  root.render(
    <React.StrictMode>
      <Suspense fallback={fallback}>
        <LDProvider>
          <Router getUserConfirmation={() => {}}>
            <App />
          </Router>
        </LDProvider>
      </Suspense>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();
})();
